let URL = {

  produccion: {
    doc: "./api/",
    api: "./api/",
    foto: "./api/foto/",
    upload: "./api/upload/",
    //val: "https://sabeeinsurance.com/#/query/",
    //tlf: '+59996905811',

    val: "https://bonaire.sabeeinsurance.com/#/query/",
    tlf: '+59996901730',

    //val: "https://test.sabeeinsurance.com/#/query/",
    //val: "https://post.sabeeinsurance.com/#/query/",
    correo: null,
    clave: null
  },

  test: {
    doc: "./api/",
    api: "https://sabeeinsurance.com/api/",
    foto: "https://sabeeinsurance.com/api/foto/",
    upload: "https://sabeeinsurance.com/api/upload/",
    val: "https://sabeeinsurance.com/#/query/",
    tlf: '+59996905811',
    correo: "wilmer78@gmail.com",
    clave: "@Paola13288051"
  },

  local: {
    doc: "./api/",
    api: "http://192.168.1.2/vue-project/Sabee/src/api/",
    foto: "http://192.168.1.2/vue-project/Sabee/src/api/foto/",
    upload: "http://192.168.1.2/vue-project/Sabee/src/api/upload/",
    val: "http://test.sabee.deliveryfaster.com/#/query/",
    tlf: '+59996905811',
    correo: "wilmer78@gmail.com",
    clave: "@Paola13288051"
  },

  localhost: {
    doc: "./api/",
    api: "http://localhost/vue-project/Sabee/src/api/",
    foto: "http://localhost/vue-project/Sabee/src/api/foto/",
    upload: "http://localhost/vue-project/Sabee/src/api/upload/",
    val: "http://test.sabee.deliveryfaster.com/#/query/",
    tlf: '+59996905811',
    correo: "wilmer78@gmail.com",
    clave: "@Paola13288051"
  },

  prueba: {
    doc: "./api/",
    api: "https://test.sabeeinsurance.com/api/",
    foto: "https://test.sabeeinsurance.com/api/foto/",
    upload: "https://test.sabeeinsurance.com/api/upload/",
    val: "https://test.sabeeinsurance.com/#/query/",
    //correo: "pnficucf@gmail.com",
    //correo: "aembassador.ve@gmail.com",
    //clave: "#Administra_2"
    tlf: '+59996905811',
    correo: "wilmer78@gmail.com",
    //correo: "wilmercolmenarez@gmail.com",
    clave: "@Paola13288051"
  },

};

export default URL['produccion'];